import * as React from "react";
import { Link } from "@reach/router";
import { useEffect } from "react";
import { navigate } from "@reach/router";


// markup
const IndexPage = () => {

  useEffect(()=>{
    navigate('/home');
  },[])

  return (
    <></>
  )
}

export default IndexPage
